/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { RxUtil } from "@/assets/util.js";
import { Radio, RadioGroup } from 'vue-ydui/dist/lib.rem/radio';
import router from '@/router/index';
import sysuser from '@/components/common/SysUser';
import rxboxs from '@/components/common/rx-boxs';
Vue.component(sysuser.name, sysuser);
Vue.component(rxboxs.name, rxboxs);
export default {
  data() {
    return {
      mailUrge: "1",
      mailObject: this.$route.params.mailObject,
      loginUser: {},
      replyContent: "",
      mailType: this.$route.params.mailType,
      operation: this.$route.params.mailType,
      orginMail: {},
      needCopySend: false,
      data: {
        receiver: this.$route.params.recUser,
        copyReceiver: "[]"
      },
      showUserDialog: false
    };
  },
  methods: {
    getInfo() {
      var url = _baseUrl + '/mobile/oa/personalInfo/getUser.do';
      this.$ajax(url).then(response => {
        this.loginUser = response.data.user;
      }).catch(error => {
        console.log(error);
      });
    },
    sendMail() {
      let recIdsData = JSON.parse(this.data.receiver) || [];
      if (recIdsData.length == 0) {
        this.errorToast("请选择收件人");
        return;
      }
      let copyIdsData = JSON.parse(this.data.copyReceiver) || [];
      let recIdsArray = [];
      let recNamesArray = [];
      let copyIdsArray = [];
      let copyNamesArray = [];
      for (var i = 0; i < recIdsData.length; i++) {
        recIdsArray.push(recIdsData[i].id);
        recNamesArray.push(recIdsData[i].text);
      }
      this.mailObject.recIds = recIdsArray.join(",");
      this.mailObject.recNames = recNamesArray.join(",");
      if (copyIdsData.length != 0) {
        for (var i = 0; i < copyIdsData.length; i++) {
          copyIdsArray.push(copyIdsData[i].id);
          copyNamesArray.push(copyIdsData[i].text);
        }
        this.mailObject.ccIds = copyIdsArray.join(",");
        this.mailObject.ccNames = copyNamesArray.join(",");
      }
      this.mailObject.content = this.replyContent;
      var url = _baseUrl + '/mobile/oa/sendMail.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          data: this.mailObject,
          urge: this.mailUrge
        }
      }).then(response => {
        if (response.data.success) {
          this.successToast("发送邮件成功", 1500);
        }
        router.push({
          name: 'mailBox'
        });
      }).catch(error => {
        console.log(error);
      });
    },
    backToMailDetail() {
      router.push({
        name: 'innerMailDetail',
        params: {
          mail: this.mailObject,
          mailType: 'recieve'
        }
      });
    },
    showContent() {
      var url = _baseUrl + '/mobile/oa/editMail.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          pkId: this.mailObject.mailId,
          operation: this.operation
        }
      }).then(response => {
        this.mailObject = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    checkSender() {
      //alert("点击了发件人");
      this.needCopySend = true;
    },
    selectUserDialog(vm) {
      var selectUser = vm.getIdVal(false);
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    }
  },
  created() {
    this.getInfo();
    this.showContent();
    console.info(this.$route.params.recUser);
  }
};